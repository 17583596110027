import React from "react"


import Layout from "../components/Layout/Layout"

import SEO from "../components/Seo/Seo"


import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading2 from '../components/Type/Heading2'
import Paragraph1 from '../components/Type/Paragraph1'

const PrivacyPage = ({location}) => 
  (
  <Layout>

    <SEO  title="Summon Privacy Policy" 
          description="Summon Privacy Policy outling how we respect and protect your privacy."
          location={location}
    />

      <PageHeader title="Privacy Policy" />

      <SectionPageMargin>
        <div>
          <Heading2>
            Our Privacy Policy
          </Heading2>
          <Paragraph1>
            Your privacy is important to us. It is Summon's policy to respect your privacy regarding any information 
            we may collect from you across our website, <a href="https://www.summon.com.au">https://www.summon.com.au</a>, 
            and other sites we own and operate.
          </Paragraph1>
          <Paragraph1>
            We only ask for personal information when we truly need it to provide a service to you. We collect it by 
            fair and lawful means, with your knowledge and consent. We also let you know why we’re 
            collecting it and how it will be used.
          </Paragraph1>
          <Paragraph1>
            We only retain collected information for as long as necessary to provide you with your requested 
            service. What data we store, we’ll protect within commercially acceptable means to prevent loss 
            and theft, as well as unauthorised access, disclosure, copying, use or modification.
          </Paragraph1>

          <Paragraph1>
            We may use your personal information to serve you specifically, such as to deliver marketing information, 
            product recommendations and non-transactional communications (e.g., email, telemarketing calls, SMS, 
            or push notifications) about us, in accordance with your marketing preferences and this privacy policy. 
            You can opt-out or change your marketing preferences at any time by emailing 
            <a href="mailto:hello@summon.com.au">hello@summon.com.au</a>
          </Paragraph1>
          
          <Paragraph1>
            We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
          </Paragraph1>
          <Paragraph1>
            Our website may link to external sites that are not operated by us. Please be aware that we have no control 
            over the content and practices of these sites, and cannot accept responsibility or liability for their 
            respective privacy policies.
          </Paragraph1>
          <Paragraph1>
            You are free to refuse our request for your personal information, with the understanding that we may be 
            unable to provide you with some of your desired services.
          </Paragraph1>
          <Paragraph1>
            Your continued use of our website will be regarded as acceptance of our practices around privacy 
            and personal information. If you have any questions about how we handle user data and personal 
            information, feel free to contact us.
          </Paragraph1>
          <Paragraph1>
            This policy is effective as of 31 October 2019.
          </Paragraph1>
        </div>
      </SectionPageMargin>
  </Layout>
)

export default PrivacyPage

